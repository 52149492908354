import { gql, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { backendPaginatedResponse } from '../common_lib_front/types/backendResponse';

const GET_GUESTS = gql`
  query GetMyGuests(
    $registrationIds: [String!]
    $fastPassesOnly: Boolean
    $hideDeleted: Boolean
    $currentOnly: Boolean
    $pageNumber: Float!
    $pageSize: Float!
  ) {
    getMyGuests(
      registrationIds: $registrationIds
      fastPassesOnly: $fastPassesOnly
      hideDeleted: $hideDeleted
      currentOnly: $currentOnly
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      success
      error
      numberPages
      data {
        guestInfo {
          userId
          firstName
          lastName
          email
          phoneNumber
        }
        communityRental {
          address
          arrivalDate
          departureDate
          communityRentalId
        }
        passes {
          passId
          startDate
          endDate
          passInfoId
          status
          paid
          url
          registrationId
          passInfo {
            name
          }
        }
        registration {
          registrationId
        }
      }
    }
  }
`;
type vars = {
  registrationIds?: string[];
  fastPassesOnly?: boolean;
  hideDeleted?: boolean;
  currentOnly?: boolean;
  pageNumber: number;
  pageSize: number;
};
type res = {
  getMyGuests: backendPaginatedResponse<invitedGuestInfo[]>;
};
export type invitedGuestInfo = {
  guestInfo: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  communityRental: {
    address: string;
    arrivalDate: string;
    departureDate: string;
    communityRentalId: string;
  };
  passes: {
    passId: string;
    startDate: string;
    endDate: string;
    passInfoId: string;
    status: string;
    paid: string;
    url?: string;
    registrationId?: string;
    passInfo: {
      name: string;
    };
  }[];
  registration: {
    registrationId: string;
  };
};

type useGetMyGuestsRes = {
  data?: invitedGuestInfo[];
  numberPages?: number;
  error?: string;
  loading: boolean;
  pageNumber: number;
  setPageNumber: (page: number | ((prev: number) => number)) => void;
};

export default function useGetMyGuests(variables: Partial<vars>): useGetMyGuestsRes {
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data, loading, error, refetch } = useQuery<res, vars>(GET_GUESTS, {
    variables: {
      pageSize: 15,
      pageNumber: 0,
      ...variables,
    },
  });

  useEffect(() => {
    refetch({
      pageNumber,
    });
  }, [pageNumber, refetch]);

  const safeSetPageNumber = useCallback(
    (page: number | ((prev: number) => number)) => {
      let safePage = typeof page === 'number' ? page : page(pageNumber);
      if (safePage < 0) {
        safePage = 0;
      } else if (data && safePage >= data.getMyGuests.numberPages) {
        safePage = data.getMyGuests.numberPages - 1;
      }
      setPageNumber(safePage);
    },
    [pageNumber, data, setPageNumber],
  );

  return {
    data: data?.getMyGuests.data || undefined,
    numberPages: data?.getMyGuests.numberPages,
    error:
      data?.getMyGuests.error ||
      (error ? 'Something went wrong. Could not get info.' : undefined),
    loading,
    pageNumber,
    setPageNumber: safeSetPageNumber,
  };
}
