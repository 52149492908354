import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Typography,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Fade,
  DialogTitle,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { ReactElement, useState, useContext, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as Alert } from '../../../assets/alertRed.svg';
import { ReactComponent as Copy } from '../../../assets/copy.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { getUrlsDynamic } from '../../../common_lib_front/configs/symlivUrls';
import { paymentInfo } from '../../../common_lib_front/types/paymentInfo';
import { rentalUnitInfo } from '../../../common_lib_front/types/rentalUnitInfo';
import { passInfoDataType } from '../../../hooks/useValidPassInfos';
import style from './property-detail.module.css';

interface Props {
  validPassInfoDatas?: passInfoDataType[];
  rentalInfo: rentalUnitInfo;
  paymentInfo: paymentInfo | undefined;
  editRental: (obj: rentalUnitInfo) => void;
  deletePayment: () => void;
  rentalUnitId: string;
  generateRentalSlug: (rentalUnitId: string, type: string, value: boolean) => void;
  refetchData: () => void;
  fetchPaymentMethods: () => void;
}
const CREATE_PAYMENT_SESSION = gql`
  mutation CreatePaymentSetupSession(
    $cancelUrl: String!
    $successUrl: String!
    $paymentMethodTypes: String
  ) {
    createPaymentSetupSession(
      cancelUrl: $cancelUrl
      successUrl: $successUrl
      paymentMethodTypes: $paymentMethodTypes
    ) {
      success
      error
      url
    }
  }
`;
type CREATE_PAYMENT_SESSION_VARS = {
  cancelUrl: string;
  successUrl: string;
  paymentMethodTypes: string;
};

type CREATE_PAYMENT_SESSION_RES = {
  createPaymentSetupSession: {
    success: boolean;
    error: string;
    url: string;
  };
};
export default function PropertyDetail(props: Props): ReactElement {
  const {
    validPassInfoDatas,
    rentalInfo,
    deletePayment,
    editRental,
    paymentInfo,
    rentalUnitId,
    generateRentalSlug,
    refetchData,
    fetchPaymentMethods,
  } = props;
  const { communityId } = useContext(CommunityContext);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [changeMethod, setChangeMethod] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [value, setValue] = useState<boolean>(false);

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (!event.target.checked) {
      setDisable(true);
      setType(type);
      setValue(event.target.checked);
    } else generateRentalSlug(rentalInfo.rentalUnitId, type, event.target.checked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    editRental({ ...rentalInfo, [event.target.name]: event.target.value });
  };

  const handleContactDetailsEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    editRental({ ...rentalInfo, [event.target.name]: event.target.checked });
  };

  const [createPaymentSession, { loading }] = useMutation<
    CREATE_PAYMENT_SESSION_RES,
    CREATE_PAYMENT_SESSION_VARS
  >(CREATE_PAYMENT_SESSION);

  const errorBox = () => {
    return (
      <Box
        component="section"
        sx={{
          marginTop: '16px',
          marginBottom: '16px',
          padding: '10px',
          border: '1px solid #FF0000',
          background: ' #FBEEEE',
          display: 'flex',
        }}
      >
        <div className={style.alertPic}>
          <Alert />
        </div>
        <Typography
          style={{
            fontSize: '16px',
            marginLeft: '20px',
            fontWeight: 400,
            marginTop: '6px',
          }}
        >
          {/* In order to enable guests to request passes where the host will pay on their
          behalf, please add a valid payment method below. */}
          This feature is currently disabled
        </Typography>
      </Box>
    );
  };
  const handlePaymentClick = (type: string) => {
    {
      {
        createPaymentSession({
          variables: {
            cancelUrl: `${window.location.origin}/${communityId}/resident/my-rentals/view-rental/${rentalUnitId}`,
            successUrl: `${window.location.origin}/${communityId}/resident/my-rentals/view-rental/${rentalUnitId}`,
            paymentMethodTypes: type,
          },
        })
          .then(res => {
            if (res.data?.createPaymentSetupSession.url) {
              window.location.href = res.data.createPaymentSetupSession.url;
            } else {
              // setAlert(res.data?.createPaymentSetupSession.error || '');
            }
          })
          .catch(err => {
            // setAlert(err.message);
          });
      }
    }
  };
  const getPaymentType = (paymentInfo: any) => {
    if (paymentInfo.type === 'us_bank_account') {
      return <Typography style={{ color: 'blue' }}>Bank</Typography>;
    }
    if (paymentInfo.type === 'card') {
      return <Typography style={{ color: 'blue' }}>{paymentInfo.card.brand}</Typography>;
    }
  };
  const getLastNumber = (paymentInfo: any, type: string) => {
    if (type === 'us_bank_account') {
      return '{' + (paymentInfo?.us_bank_account?.last4 || '') + '}';
    }
    if (type === 'card') {
      return '{' + (paymentInfo?.card?.last4 || '') + '}';
    }
  };
  const copyContent = async (text: string) => {
    try {
      console.log('text', text);
      await navigator.clipboard.writeText(text);
      alert('copied');
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <div>
      {(confirmDelete || changeMethod) && (
        <Dialog
          open={confirmDelete || changeMethod}
          onClose={() => {
            setConfirmDelete(false);
            setChangeMethod(false);
          }}
        >
          <DialogContent className={style.container}>
            <Typography className={style.dialogHeading}>
              {confirmDelete ? 'Remove payment method' : 'Change payment method'}
            </Typography>
            <p className={style.instruction}>
              {confirmDelete
                ? 'Are you sure you want to remove your payment method? Your payment method will be removed across all of your properties.'
                : 'Are you sure you want to remove your payment method in order to change it to another payment method? Your payment method will be removed across all of your properties.'}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              title="Cancel"
              onClick={() => {
                setConfirmDelete(false);
                setChangeMethod(false);
              }}
              size="large"
              variant="contained"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                await deletePayment();
                setConfirmDelete(false);
                setChangeMethod(false);
                await fetchPaymentMethods();
                await refetchData();
              }}
              size="large"
              // color="blue"
            >
              {confirmDelete ? 'REMOVE' : 'REMOVE & CHANGE'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {disable && (
        <Dialog
          open={disable}
          onClose={() => {
            setDisable(false);
            setType('');
            setValue(false);
          }}
        >
          <DialogTitle>Reminder</DialogTitle>
          <DialogContent className={style.container}>
            <p className={style.instruction}>
              This link will no longer work. If you have shared it with guests or have it
              in your reservation platform, please notify the guest or remove the link to
              avoid guest confusion.{' '}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              title="Cancel"
              onClick={() => {
                setDisable(false);
                setType('');
                setValue(false);
              }}
              size="large"
              variant="outlined"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                await generateRentalSlug(rentalInfo.rentalUnitId, type, value);
                setDisable(false);
                setType('');
                setValue(false);
              }}
              size="large"
            >
              GOT IT
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={copied}
        autoHideDuration={6000}
        onClose={() => setCopied(false)}
        message="Copied"
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      />
      <Grid
        spacing={2}
        container
        direction="row"
        sx={{
          justifyContent: 'space-around',
          alignItems: 'start',
          padding: '10px',
        }}
      >
        <Grid item xs={10} md={6}>
          <Typography className={style.heading}>Property Pass Links</Typography>
          <Typography className={style.subHeading}>
            Share the pass links below to allow your guest to generate their own passes{' '}
          </Typography>
          <Card elevation={0} style={{ marginTop: '20px', padding: '10px' }}>
            <Typography className={style.passHeading}>Daily pass limits</Typography>
            <div style={{ marginTop: '4px', padding: '0px' }}>
              {(() => {
                const withOverride = validPassInfoDatas?.filter(
                  e => e?.capacityOverride > 0,
                );
                return [
                  ...(withOverride || [])?.map(item => {
                    const passLimit = item.capacityOverride;
                    return (
                      <Card
                        key={item.name}
                        style={{
                          padding: '16px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '16.9px',
                        }}
                        elevation={0}
                        variant="outlined"
                      >
                        <Typography style={{ fontSize: '14px' }}>{item.name}</Typography>
                        <div style={{ display: 'flex' }}>
                          <Typography
                            style={{ fontWeight: 600 }}
                          >{`${passLimit}`}</Typography>
                          <Typography>{'/Day'}</Typography>
                        </div>
                      </Card>
                    );
                  }),
                  (withOverride?.length || 0) < validPassInfoDatas!.length && (
                    <Card
                      key={'all-other-pass-types'}
                      style={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '16.9px',
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <Typography style={{ fontSize: '14px' }}>
                        {'All Other Passes (Combined Total)'}
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <Typography
                          style={{ fontWeight: 600 }}
                        >{`${rentalInfo?.occupancy}`}</Typography>
                        <Typography>{'/Day'}</Typography>
                      </div>
                    </Card>
                  ),
                ];
              })()}
            </div>
          </Card>
          <Card style={{ marginTop: '20px', padding: '10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginRight: '10px',
              }}
            >
              <Typography className={style.payHeading}>
                Host pays for passes (Free for guest)
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={rentalInfo.hostPay}
                    onChange={event => handleSwitchChange(event, 'host')}
                    disabled={!paymentInfo}
                    name="hostPay"
                  />
                }
                label={rentalInfo.hostPay ? 'Enabled' : 'Disabled'}
              />
            </div>
            <Typography className={style.payText}>
              {!rentalInfo.hostPay
                ? ` Enable and share this link with guests so do they can generate passes that
              will be charged to the host account`
                : 'When a guest visits this link, they will not be asked to pay for their passes. Your payment method below will be charged.'}
            </Typography>
            {paymentInfo ? (
              <Card
                elevation={0}
                style={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {getPaymentType(paymentInfo)}
                  <Typography style={{ marginLeft: '8px' }}>
                    ending with {getLastNumber(paymentInfo, paymentInfo.type)}
                  </Typography>
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography
                    className={style.actionBtn}
                    onClick={() => setConfirmDelete(true)}
                  >
                    Remove
                  </Typography>

                  <Typography
                    className={style.actionBtn}
                    onClick={() => setChangeMethod(true)}
                  >
                    Change
                  </Typography>
                </div>
              </Card>
            ) : null}
            {/* {!rentalInfo.hostPay && !paymentInfo && errorBox()} */}
            {errorBox()}
            {!paymentInfo && (
              <div style={{ display: 'flex', marginTop: '6px !important' }}>
                <Typography
                  className={style.actionBtn}
                  onClick={() => handlePaymentClick('card')}
                >
                  Add a credit card
                </Typography>
                <Typography className={style.actionBtn}> or </Typography>
                <Typography
                  className={style.actionBtn}
                  onClick={() => handlePaymentClick('us_bank_account')}
                >
                  Add a bank account
                </Typography>
              </div>
            )}
            {rentalInfo.hostPay && rentalInfo.hostPropertySlug && (
              <Card variant="outlined" style={{ padding: '8px', marginTop: '16px' }}>
                <TextField
                  fullWidth
                  id="myInput"
                  variant="standard"
                  type="text"
                  value={`${getUrlsDynamic('guest').front}/${communityId}/guest/${
                    rentalInfo.hostPropertySlug
                  }/get-passes`}
                  sx={{
                    input: {
                      color: '#0000FF',
                      textDecoration: 'underline',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '19.36px',
                      textAlign: 'left',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <CopyToClipboard
                        text={`${getUrlsDynamic('guest').front}/${communityId}/guest/${
                          rentalInfo.hostPropertySlug
                        }/get-passes`}
                        onCopy={() => setCopied(true)}
                      >
                        <Copy width={20} height={20} cursor={'pointer'} />
                      </CopyToClipboard>
                    ),
                  }}
                />
              </Card>
            )}
          </Card>
          <Card style={{ marginTop: '20px', marginBottom: '20px', padding: '10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginRight: '10px',
              }}
            >
              <Typography className={style.payHeading}>Guest pays for passes</Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={rentalInfo.guestPay}
                    onChange={event => handleSwitchChange(event, 'guest')}
                    name="guestPay"
                  />
                }
                label={rentalInfo.guestPay ? 'Enabled' : 'Disabled'}
              />
            </div>
            <Typography className={style.payText}>
              When a guest visits this link, guests can generate & pay for their own
              passes.
            </Typography>
            {rentalInfo.guestPay && rentalInfo.guestPropertySlug && (
              <Card variant="outlined" style={{ padding: '8px', marginTop: '16px' }}>
                <TextField
                  fullWidth
                  id="myInput"
                  variant="standard"
                  type="text"
                  value={`${getUrlsDynamic('guest').front}/${communityId}/guest/${
                    rentalInfo.guestPropertySlug
                  }/get-passes`}
                  sx={{
                    input: {
                      color: '#0000FF',
                      textDecoration: 'underline',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '19.36px',
                      textAlign: 'left',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <CopyToClipboard
                        text={`${getUrlsDynamic('guest').front}/${communityId}/guest/${
                          rentalInfo.guestPropertySlug
                        }/get-passes`}
                        onCopy={() => setCopied(true)}
                      >
                        <Copy width={20} height={20} cursor={'pointer'} />
                      </CopyToClipboard>
                    ),
                  }}
                />
              </Card>
            )}
          </Card>
        </Grid>
        <Grid item xs={10} md={6}>
          <Typography className={style.heading}>Property Details</Typography>
          <Typography className={style.subHeading}>
            Property details require admin approval. To alter your rental property details
            and application, contact your admin
          </Typography>
          <Card
            style={{
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16.9px',
            }}
            variant="outlined"
          >
            <Typography>{rentalInfo?.address}</Typography>
          </Card>
          <div style={{ marginTop: '20px' }}>
            <Typography className={style.heading}>
              Rental Management Information
            </Typography>
            <Typography className={style.subHeading}>
              Rental property managers are expected to return all emergency calls within 2
              hours.
            </Typography>
            <div>
              <Card style={{ marginTop: '20px', marginBottom: '20px', padding: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginRight: '10px',
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    Distribute Contact to Guest
                  </Typography>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={!!rentalInfo.enableContactDetails}
                        onChange={event => handleContactDetailsEnable(event)}
                        name="enableContactDetails"
                      />
                    }
                    label={rentalInfo.enableContactDetails ? 'Enabled' : 'Disabled'}
                  />
                </div>
                <Typography
                  style={{ fontSize: '16px', marginLeft: '8px', fontWeight: 400 }}
                >
                  By Enabling, we will provide the contact info to guests giving them an
                  easy way to resolve potentials issues.
                </Typography>
              </Card>
            </div>
            <div style={{ marginTop: '10px' }}>
              <form>
                <Typography style={{ fontSize: '14px' }}>
                  Management Company Name
                </Typography>
                <Card variant="outlined" style={{ padding: '8px', marginTop: '6px' }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    value={rentalInfo?.companyName || ''}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Card>
                <Typography style={{ fontSize: '14px' }}>
                  Property Manager Name
                </Typography>
                <Card variant="outlined" style={{ padding: '8px', marginTop: '6px' }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="propertyManagerName"
                    onChange={handleChange}
                    value={rentalInfo?.propertyManagerName || ''}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Card>
                <Typography style={{ fontSize: '14px' }}>
                  Property Manager Phone Number
                </Typography>
                <Card variant="outlined" style={{ padding: '8px', marginTop: '6px' }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    name="propertyManagerPhone"
                    onChange={handleChange}
                    value={rentalInfo?.propertyManagerPhone || ''}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Card>
                <Typography style={{ fontSize: '14px' }}>
                  Primary Emergency Contact Name
                </Typography>
                <Card variant="outlined" style={{ padding: '8px', marginTop: '6px' }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    name="primaryEmergencyContactName"
                    onChange={handleChange}
                    value={rentalInfo?.primaryEmergencyContactName || ''}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Card>
                <Typography style={{ fontSize: '14px' }}>
                  Primary Emergency Contact Number
                </Typography>
                <Card variant="outlined" style={{ padding: '8px', marginTop: '6px' }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    name="primaryEmergencyPhone"
                    onChange={handleChange}
                    value={rentalInfo?.primaryEmergencyPhone || ''}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Card>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
