import * as Sentry from '@sentry/react';
import csv from 'csvtojson';
import React, { useCallback, useMemo, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../../../common_lib_front/components/popUp/popUp';
import { formatDateForInput } from '../../../../../common_lib_front/utilities/formatDate';
import { InviteGuestDispatchType } from '../../useInviteGuest';
import style from './uploadGuestList.module.css';

const allKeys = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'address',
  'arrivalDate',
  'departureDate',
  'numberOfPasses',
  'willPay',
] as const;

type keyOptions = (typeof allKeys)[number];
type headerConfig = Record<keyOptions, string | null>;

const defaultHaders: headerConfig = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone Number',
  address: 'Rental Address',
  arrivalDate: 'Arrival Date',
  departureDate: 'Departure Date',
  numberOfPasses: 'Number of Passes',
  willPay: 'Pay for Guest Pass',
};

const fastPassHeaders: headerConfig = {
  firstName: 'First Name (optional)',
  lastName: 'Last Name (optional)',
  email: null,
  phone: null,
  address: 'Rental Address',
  arrivalDate: 'Arrival Date',
  departureDate: 'Departure Date',
  numberOfPasses: '1',
  willPay: null,
};

const exampleData: Record<keyOptions, string>[] = [
  {
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@ex.com',
    phone: '1(000)000-0000',
    address: '123 Beach Front Blvd. #23',
    arrivalDate: '2023/7/28',
    departureDate: '2023/8/28',
    numberOfPasses: '2',
    willPay: 'Y',
  },
  {
    firstName: 'Jane',
    lastName: 'Doe',
    email: 'jane.doe@ex.com',
    phone: '1(222)222-2222',
    address: '567 Beach Front Blvd. #11',
    arrivalDate: '2023/3/11',
    departureDate: '2023/3/17',
    numberOfPasses: '1',
    willPay: 'N',
  },
];

type uploadProps = {
  editData: InviteGuestDispatchType;
};
export default function UploadGuestList(props: uploadProps): React.ReactElement {
  const history = useHistory();
  const { editData } = props;
  const { flow } = useParams<{ flow?: string }>();
  const [parseData, setParseData] = useState<Record<string, string>[]>([]);
  console.log(parseData);
  // const { parse, data } = useCsvParser();
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const hostMustPay = featuresConfig?.host?.inviteGuest?.hostMustPay ?? false;
  // NOTE multiplePasses option not defined in any config files at time of writing
  // seems to default to true?
  const multiplePassesAllowed = featuresConfig?.host?.inviteGuest?.multiplePasses ?? true;
  const [headers, keys] = useMemo(() => {
    let selectedHeaders = defaultHaders;
    if (flow === 'fast-pass') {
      selectedHeaders = fastPassHeaders;
    }
    const selectedKeys = allKeys.filter(k => !!selectedHeaders[k]);
    return [selectedHeaders, selectedKeys];
  }, [flow]);
  if (hostMustPay == true && keys.indexOf('willPay') >= 0) {
    keys.splice(keys.indexOf('willPay'), 1);
  }
  if (!multiplePassesAllowed && keys.indexOf('numberOfPasses') >= 0) {
    keys.splice(keys.indexOf('numberOfPasses'), 1);
  }
  const acceptHandler = useCallback(() => {
    editData({ type: 'clear', payload: {} });
    parseData.forEach(line => {
      editData({
        type: 'addRecipient',
        payload: {
          recipientData: {
            firstName: line.firstName,
            lastName: line.lastName,
            email: line.email,
            phoneNumber: line.phone,
            willPayForPass: line.willPay ? /(y|t)/i.test(line.willPay) : false,
            address: line.address,
            arrivalDate: formatDateForInput(line.arrivalDate),
            departureDate: formatDateForInput(line.departureDate),
            numberOfPasses: line.numberOfPasses,
          },
          passData: {
            // startDate: formatDateForInput(line.arrivalDate),
            // endDate: formatDateForInput(line.departureDate),
          },
        },
      });
    });
    Sentry.captureMessage(`${communityId} Invite Guest upload telemetry`, {
      extra: {
        data: {
          communityId,
          numRows: parseData.length,
          avgPassesPerRow:
            parseData
              .map(line => Number(line.numberOfPasses))
              .reduce((sum, curr) => curr + sum) / parseData.length,
        },
      },
    });
    history.goBack();
  }, [parseData, editData, history]);
  const downloadExampleHandler = useCallback(() => {
    const a = document.createElement('a');
    let res = '';
    keys.forEach(k => {
      res += `${headers[k]},`;
    });
    res += '\n';
    exampleData.forEach(row => {
      keys.forEach(k => {
        res += `${row[k]},`;
      });
      res += '\n';
    });
    const file = new Blob([res], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    a.download = 'ExampleSpreadsheet.csv';
    a.click();
  }, [headers, keys]);

  return (
    <PopUp title="Upload Guest List" close={() => history.goBack()}>
      <div className={style.container}>
        <div className={style.upperBox}>
          <div className={style.titleBox}>
            <h1>Example Spreadsheet</h1>
          </div>
          <div className={style.tableBox}>
            <GenericAlert color="red">
              Please make sure that all of your columns use the exact order as shown here.
              All data columns must be filled out. All uploaded addresses must be in your
              approved addresses list. Please make sure that all of your data is
              accurately filled out and that your arrival and departure dates do not
              overlap other reservations for the selected unit.
            </GenericAlert>
            <div className={style.scrollBox}>
              <table width="100%" cellSpacing={0} className={style.table}>
                <thead>
                  <tr className={style.firstTr}>
                    {keys.map((k, idx) => (
                      <th key={idx}>{String.fromCharCode(65 + idx)}</th>
                    ))}
                  </tr>
                  <tr>
                    {keys.map(k => (
                      <th key={k}>{headers[k]}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {exampleData.map((row, idx) => (
                    <tr key={idx}>
                      {keys.map((k, idx) => (
                        <td key={idx}>{row[k] || ''}</td>
                      ))}
                    </tr>
                  ))}
                  {/* //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        //   <GenericButton
        //     title="Download Example Spreadsheet"
        //     clickHandler={downloadExampleHandler}
        //   />
        // </div>
        // <div>
        //   <label
        //     htmlFor="upload"
        //   >
        //     CSV File:
        //     <input
        //       id="upload"
        //       type="file"
        //       accept=".csv"
        //       multiple
        //       onChange={(ev) => {
        //         parse(ev.target.files); */}
                </tbody>
              </table>
            </div>
          </div>
          <div className={style.btnDownload}>
            <GenericButton
              color="blue"
              size="medium"
              title="Download Example Spreadsheet"
              clickHandler={downloadExampleHandler}
            />
          </div>
        </div>
        <div className={style.uploadActionBox}>
          <div className={style.titleBox}>
            <h1>Import CSV File</h1>
          </div>
          <div className={style.importBox}>
            <label htmlFor="upload">
              <div className={style.csv}>CSV File:</div>
              <div className={style.uploadBox}>
                <div className={style.chooseBtn}>Choose File</div>
                <div className={style.cover} />

                <input
                  className={style.upload}
                  // style={{ opacity: 0 }}
                  id="upload"
                  type="file"
                  accept=".csv"
                  multiple
                  onChange={ev => {
                    const file = ev.target.files?.[0];
                    if (!file) {
                      setParseData([]);
                      return;
                    }
                    const reader = new FileReader();
                    reader.onload = readEv => {
                      if (typeof readEv.target?.result !== 'string') return;
                      csv({
                        trim: true,
                        headers: allKeys as unknown as string[],
                      })
                        .fromString(readEv.target.result)
                        .then((json: Record<string, string>[]) => {
                          setParseData(json);
                        });
                    };
                    reader.readAsText(file);
                  }}
                />
              </div>
            </label>
          </div>
        </div>
        {/* // <div>
  //   <h2>
  //     Data Preview
  //   </h2>
  //   <GenericButton
  //     title="Accept"
  //     clickHandler={acceptHandler}
  //   />
  //   <table>
  //     <thead>
  //       <tr>
  //         {keys.map((k) => (
  //           <th>{headers[k]}</th>
  //         ))}
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {data.map((row) => (
  //         <tr>
  //           {keys.map((k) => (
                //             <td>{row[k] || ''}</td> */}
        <div className={style.upperBox}>
          <div className={style.titleBox}>
            <h1>Data Preview</h1>
          </div>
          <div className={style.acceptBtn}>
            <GenericButton
              color="yellow"
              size="medium"
              title="Accept"
              clickHandler={acceptHandler}
              //   () => {
              //   editData({ type: 'clear', payload: {} });
              //   data.forEach((line) => {
              //     editData({
              //       type: 'addRecipient',
              //       payload: {
              //         recipientData: {
              //           firstName: line.firstName,
              //           lastName: line.lastName,
              //           email: line.email,
              //           phoneNumber: line.phone,
              //           willPayForPass: !!(line.willPay === 'y' || line.willPay === 'Y'),
              //           address: line.address,
              //         },
              //         passData: {
              //           startDate: formatDateForInput(line.arrivalDate),
              //           endDate: formatDateForInput(line.departureDate),
              //         },
              //       },
              //     });
              //     history.goBack();
              //   });
              // }}
            />
          </div>
          <div className={style.tableBox}>
            <div className={style.scrollBox}>
              <table width="100%" cellSpacing={0} className={style.table}>
                <thead>
                  <tr className={style.firstTr}>
                    {keys.map((k, idx) => (
                      <th key={idx}>{headers[k]}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {parseData.map((row, idx) => (
                    <tr key={idx}>
                      {keys.map((k, idx) => (
                        <td key={idx}>{row[k] || ''}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  );
}
