import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import SearchSelector from '../../common_lib_front/components/searchSelector/searchSelector';
import {
  formatDateForInput,
  formatDateForInputUTC,
} from '../../common_lib_front/utilities/formatDate';
import { useGetHostRentals } from '../../hooks/useGetHostRentals';
import { passInfoDataType, useValidPassInfos } from '../../hooks/useValidPassInfos';
import { InvitePassInfo, RecipientInfo, formConfigType } from './inviteGuestForm';
import style from './inviteGuestForm.module.css';

type rentalFormProps = {
  idx: number;
  data: RecipientInfo;
  paymentMode?: boolean;
  formConfig: formConfigType;
  editRecipient: (data: Partial<RecipientInfo>) => void;
  editRental: (idx: number, data: Partial<InvitePassInfo>) => void;
  deleteRecipient: () => void;
  deleteRental?: (rentalIdx: number) => void;
  addRental?: () => void;
  disabled?: boolean;
  targetPortal?: 'guest' | 'vendor';
};

export default function InviteGuestRentalForm(
  props: rentalFormProps,
): React.ReactElement {
  const {
    idx,
    data,
    paymentMode,
    formConfig,
    editRecipient,
    editRental,
    deleteRental,
    addRental,
    // passInfoData,
    disabled,
    targetPortal,
  } = props;

  let rentals;
  if (paymentMode === false) {
    rentals = style.rentals;
  } else {
    rentals = style.rentalsAuto;
  }

  const { data: myRentals } = useGetHostRentals({
    variables: {
      hostInfoId: null,
      approvedOnly: true,
    },
  });
  const [hasUserSetPassType, setHasUserSetPassType] = useState<boolean>(false);
  const { validPassInfoDatas, allPassInfoDatas, specifficPassInfoData } =
    useValidPassInfos({
      portal: targetPortal,
      specifficPassInfoId: data.passes[0]?.passInfoId,
      startDate: data.arrivalDate,
      endDate: data.departureDate,
      filters: [p => p.durationInfo.type !== 0],
      filterDateRange: true,
      filterIssuable: true,
      specifficPassMustBeInFilter: true,
    });

  const setPassTypeOnDate = useCallback(
    (startDate: string, endDate: string) => {
      if (hasUserSetPassType) return;
      const sortFn = (a: passInfoDataType, b: passInfoDataType) => {
        if (
          a.durationInfo.maxDuration === undefined &&
          b.durationInfo.maxDuration === undefined
        ) {
          return 0;
        }
        if (a.durationInfo.maxDuration === undefined) return -1;
        if (b.durationInfo.maxDuration === undefined) return 1;
        return a.durationInfo.maxDuration - b.durationInfo.maxDuration;
      };
      let duration = 0;
      if (startDate && endDate) {
        duration =
          (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          1000 / // milisecond to second
          60 / // second to minute
          60 / // minute to hour
          24; // hour to day
      }
      const pids = [...validPassInfoDatas]
        .filter(
          pid =>
            pid.durationInfo.maxDuration === undefined ||
            pid.durationInfo.maxDuration >= duration,
        )
        .sort(sortFn);
      const suggestedPid = validPassInfoDatas[0]?.passInfoId;
      data.passes.forEach((p, idx) => {
        if (p.passInfoId !== suggestedPid) {
          editRental(idx, { passInfoId: suggestedPid });
        }
      });
    },
    [hasUserSetPassType, validPassInfoDatas, editRental, data.passes],
  );

  // set default rental if none is selectd and only one option exists
  useEffect(() => {
    if (myRentals?.getRentalsByHost.data?.length === 1 && data.address === '') {
      const defaultAddress = myRentals.getRentalsByHost.data[0].address;
      if (!defaultAddress) return;
      editRecipient({
        address: defaultAddress,
      });
    }
  }, [myRentals, data, editRecipient]);

  const validateAddress = useCallback(
    async (partial: string): Promise<string[]> =>
      myRentals?.getRentalsByHost.data
        ?.filter(rental => rental.address.includes(partial))
        .map(rental => rental.address) || [],
    [myRentals],
  );

  let rentalInputsBoxStyle: string | undefined;
  if (addRental) {
    rentalInputsBoxStyle = style.mutipleRentalItem;
  } else {
    rentalInputsBoxStyle = style.item;
  }
  const { communityId } = useContext(CommunityContext);

  const now = new Date();
  return (
    <div className={rentals}>
      <div className={addRental ? style.rentalInnerBox : ''}>
        {/* community rental info */}
        <div className={rentalInputsBoxStyle}>
          <div className={style.firstLine}>
            <div className={style.number}>#1</div>
            <div className={style.firstLineContent}>
              {/* {!formConfig.address?.hidden && (
                <div className={style.searchInput}>
                  <SearchSelector
                    htmlFor={`recipient-${idx}-address`}
                    // title="Rental Address of Guest's Stay"
                    title={`${formConfig.address?.label || 'Address'}${
                      formConfig.address?.optional ? ' (Optional)' : ''
                    }`}
                    value={data.address}
                    fetch={validateAddress}
                    highLightOnFocus
                    noMatchAlert="Please select an approved address"
                    addressValues={myRentals?.getRentalsByHost.data?.map(r => r.address)}
                    changeHandler={val => editRecipient({ address: val })}
                    disabled={disabled}
                  />
                </div>
              )} */}
              <div className={style.dateinputShortBox}>
                <div className={style.dateInput}>
                  <InputField
                    disabled={!data.arrivalDate || !data.departureDate}
                    htmlFor={`recipient-${0}-start-${0}`}
                    labelTitle="Start Date"
                    inputType="date"
                    max={data.departureDate}
                    inputValue={data.passes[0].startDate}
                    changeHandler={e => {
                      editRental(0, { startDate: e.target.value });
                      setPassTypeOnDate(e.target.value, data.passes[0].endDate);
                    }}
                    min={data.arrivalDate}
                    required
                    smallSize
                    highlightOnFocus
                  />
                </div>
                <div className={style.dateInput}>
                  <InputField
                    htmlFor={`recipient-${0}-end-${0}`}
                    labelTitle="End Date"
                    inputType="date"
                    min={data.arrivalDate}
                    disabled={!data.arrivalDate || !data.departureDate}
                    inputValue={data.passes[0].endDate}
                    // inputValue={formatDateForInput(
                    //   specifficPassInfoData?.durationInfo.endDate,
                    // )}
                    max={data.departureDate}
                    changeHandler={e => {
                      editRental(0, { endDate: e.target.value });
                      setPassTypeOnDate(data.passes[0].startDate, e.target.value);
                    }}
                    required
                    smallSize
                    highlightOnFocus
                  />
                </div>
              </div>
              {/* Pass info selection */}
              {!formConfig.passType?.hidden && allPassInfoDatas.length > 1 ? (
                <div className={style.dateinputShortBox}>
                  <div className={style.dateInput}>
                    <label
                      htmlFor={`recipient-${idx}-passInfoId-all`}
                      className={style.passTypeLabel}
                    >
                      Pass Type
                      <select
                        id={`recipient-${idx}-passInfoId-all`}
                        required
                        value={data?.passes[0]?.passInfoId || ''}
                        disabled={disabled}
                        onChange={e => {
                          setHasUserSetPassType(true);
                          editRental(0, { passInfoId: e.target.value });

                          // data.passes.forEach((_, pId) => {
                          //   editRental(pId, { passInfoId: e.target.value });
                          // });
                        }}
                      >
                        <option hidden>No Pass Type Selected</option>
                        {validPassInfoDatas?.map(pid => (
                          <option key={pid.passInfoId} value={pid.passInfoId}>
                            {pid.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* passes info */}
        {addRental &&
          data.passes.slice(1).map((pass, i) => (
            <div className={rentalInputsBoxStyle} key={i}>
              {/* {paymentMode
                ? (
                  <div className={style.itemCheck}>
                    <input type="checkbox" className={style.itemCheckbox} />
                  </div>
                ) : null} */}
              <div className={style.rentalItems}>
                <div className={style.number}>{`#${i + 2}`}</div>
                <div className={style.rentalInputBox}>
                  <div className={style.dateinputShortBox}>
                    <div className={style.dateInput}>
                      <InputField
                        htmlFor={`recipient-${idx}-start-${i + 1}`}
                        labelTitle="Start Date"
                        inputType="date"
                        max={data.departureDate}
                        disabled={!data.arrivalDate || !data.departureDate}
                        // max={formatDateForInput(
                        //   specifficPassInfoData?.durationInfo.maxStartDate,
                        // )}
                        inputValue={pass?.startDate}
                        // inputValue={formatDateForInput(
                        //   specifficPassInfoData?.durationInfo.startDate,
                        // )}
                        min={data.arrivalDate}
                        // min={formatDateForInput(
                        //   specifficPassInfoData?.durationInfo.minStartDate,
                        // )}
                        changeHandler={e => {
                          editRental(i + 1, { startDate: e.target.value });
                        }}
                        required
                        smallSize
                        highlightOnFocus
                      />
                    </div>
                    <div className={style.dateInput}>
                      <InputField
                        htmlFor={`recipient-${idx}-end-${i + 1}`}
                        labelTitle="End Date"
                        inputType="date"
                        min={data.arrivalDate}
                        disabled={!data.arrivalDate || !data.departureDate}
                        // min={formatDateForInput(
                        //   specifficPassInfoData?.durationInfo.minEndDate,
                        // )}
                        max={data.departureDate}
                        // max={formatDateForInput(
                        //   specifficPassInfoData?.durationInfo.maxEndDate,
                        // )}
                        inputValue={pass?.endDate}
                        // inputValue={formatDateForInput(
                        //   specifficPassInfoData?.durationInfo.endDate,
                        // )}
                        changeHandler={e => {
                          editRental(i + 1, { endDate: e.target.value });
                        }}
                        required
                        smallSize
                        highlightOnFocus
                      />
                    </div>
                  </div>
                  {allPassInfoDatas.length > 1 || !pass.passInfoId ? (
                    <div className={style.dateinputShortBox}>
                      <div className={style.dateInput}>
                        <label
                          htmlFor={`recipient-${idx}-passInfoId-${i}`}
                          className={style.passTypeLabel}
                        >
                          Pass Type
                          <select
                            id={`recipient-${idx}-passInfoId-${i}`}
                            required
                            value={pass.passInfoId}
                            onChange={e => {
                              setHasUserSetPassType(true);
                              editRental(i + 1, { passInfoId: e.target.value });
                            }}
                            //disabled
                          >
                            <option hidden>No Pass Type Selected</option>
                            {validPassInfoDatas?.map(pid => (
                              <option key={pid.passInfoId} value={pid.passInfoId}>
                                {pid.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>
                {deleteRental && (
                  <>
                    {data.passes.length > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          deleteRental(i);
                        }}
                        className={style.delRentalBtn}
                        disabled={disabled}
                      >
                        x
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}

        {addRental && communityId !== 'grandcomplex' && (
          <div className={style.addBtnBox}>
            <GenericButton
              title="+ Add Pass"
              clickHandler={() => addRental()}
              className={style.addRentalBtn}
              disabled={disabled}
            />
          </div>
        )}
      </div>{' '}
    </div>
  );
}

InviteGuestRentalForm.defaultProps = {
  addRental: undefined,
  deleteRental: undefined,
  paymentMode: false,
  passInfoData: undefined,
};
